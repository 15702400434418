const VirtualAudience = () => {
  return (
    <div className='virtual-audience'>
      <h2>join our studio audience</h2>
      <p>It&apos;s your chance to get up close and personal with Drew! Reserve your seat in our studio audience today. And return here for full coverage if you need beauty tips to get yourself camera-ready!</p>
      <a href="https://1iota.com/show/1217/the-drew-barrymore-show" target="_blank" rel="noopener noreferrer" className="virtual-audience__btn">join now</a>
    </div>
  );
};

export default VirtualAudience;
