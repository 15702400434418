import { Fragment } from 'react';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import useCaro from '~/hooks/useCaro';
import SiteSettings from '~/siteSettings';
import { getIcon, min2secs, isGiveAwayLink } from '~/utils/utils';
import VideoPreview from '../videoPreview/videoPreview';
import LinkTracker from '~/components/linkTracker/linkTracker';

/**
 * Content Carousel
 * @category Component
 * @extends Component
 * @summary Displays the content carousel block.
 * @prop {Object} items - The block items.
 * @prop {Object} title - The block title.
 * @prop {bool} hasLoadMore - Does the item have load more.
 * @prop {function} loadMore - Function to call if user loads more.
 * @prop {bool} loading - Loading state.
 * @prop {int} count - Total result count.
 * @prop {bool} hideTypeIcons - Should we hide the type icons?.
 * @prop {string} imgStyleLg - If provided, use this image style in lieu of the LG default.
 * @prop {string} imgStyleSm - If provided, use this image style in lieu of the SM default.
 * @prop {string} loop - Should the carousel loop? Accepted values 'yes' & 'no'.
 * @prop {function} headingOveride - If provided, this function should return the custom heading override.
 * @return {Node} React node containing the content carousel.
 */

const CarouselContent = (props) => {
  const { items, title, hasLoadMore, loadMore, loading, count, hideTypeIcons, imgStyleLg, imgStyleSm, loop, headingOveride } = props;
  const { embla, viewportRef, scrollNext, scrollPrev, selectedIndex, isInteracted, reloadEmbla } = useCaro({
    loop: loop === 'yes',
    align: 'start'
  });

  // Assign Image Styles so we can interpolate
  const _imgStyleLg = imgStyleLg || 'lg';
  const _imgStyleSm = imgStyleSm || 'sm';

  // Guard again nulls in content list
  const filteredItems = items.filter(item => item !== null);

  const _loadMore = async () => {
    await loadMore();
    reloadEmbla();
  }

  return (
    <div className='x-hidden wrap-relative'>
      <div className='constrain'>
        <div className='carousel-content'>
          { headingOveride ? headingOveride() :
            <h2 className='carousel-content__heading'>
              { title }
            </h2>
          }
          <div className='carousel-content__viewport' ref={viewportRef}>
            <div className='carousel-content__container'>
              {filteredItems.map((slide, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`content-caro-${ slide?.nid }-${ i }`}>
                  { slide?.path &&
                    <div className='card'>
                      <LinkTracker
                        destinationUrl={ slide?.path }
                        linkCta={ slide?.title.toLowerCase() }
                        linkFormat='tile'
                        linkRegion={ title }
                        linkSubRegion={`carousel - ${i + 1}`}
                      >
                        <Link href={ slide?.path }>
                          <a
                            aria-label={`Visit ${ slide?.title }`}
                            data-autotrack='false'
                            target={ isGiveAwayLink(slide?.path) ? '_blank' : '_self'}
                          >
                            <div className='placed'>
                              { slide?.field_image?.field_image && slide?.__typename === 'video' &&
                                <VideoPreview item={ slide }>
                                  <picture>
                                    <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ slide?.field_image?.field_image?.[_imgStyleSm]?.url } />
                                    <img
                                      src={ slide?.field_image?.field_image?.[_imgStyleLg]?.url }
                                      alt={ slide?.field_image?.alt }
                                      width={ slide?.field_image?.field_image?.[_imgStyleLg]?.width }
                                      height={ slide?.field_image?.field_image?.[_imgStyleLg]?.height }
                                      className='card__img placed-elem'
                                      loading='lazy'
                                    />
                                  </picture>
                                </VideoPreview>
                              }
                              { (slide?.field_image || slide?.field_secondary_image) && slide?.__typename !== 'video' &&
                                <picture>
                                  <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ slide?.field_secondary_image ? slide?.field_secondary_image?.field_image?.field_image?.sm?.url : slide?.field_image?.field_image?.sm?.url } />
                                  <img
                                    src={ slide?.field_secondary_image ? slide?.field_secondary_image?.field_image?.field_image?.lg?.url : slide?.field_image?.field_image?.lg?.url }
                                    alt={ slide?.field_secondary_image ? slide?.field_secondary_image?.field_image?.alt : slide?.field_image?.alt }
                                    width={ slide?.field_secondary_image ? slide?.field_secondary_image?.field_image?.field_image?.width : slide?.field_image?.field_image?.width }
                                    height={ slide?.field_secondary_image ? slide?.field_secondary_image?.field_image?.field_image?.height : slide?.field_image?.field_image?.height }
                                    className='card__img placed-elem'
                                    loading='lazy'
                                  />
                                </picture>
                              }
                              <div className='placed-275x154'></div>
                              { slide?.field_video_mpx_id && slide?.field_video_mpx_id?.duration !== undefined &&
                                <div className='block-videos__duration'>
                                  { min2secs(slide?.field_video_mpx_id?.duration) }
                                </div>
                              }
                            </div>
                            <h3>
                              {!hideTypeIcons &&
                                <div className='card__type-icon'>
                                  <ReactSVG src={ getIcon(slide?.__typename) } />
                                </div>
                              }
                              { slide?.title }
                            </h3>
                          </a>
                        </Link>
                      </LinkTracker>
                    </div>
                  }
                </Fragment>
              ))}
              { hasLoadMore && count > filteredItems.length &&
                <div>
                  <LinkTracker
                    destinationUrl=''
                    linkCta='see more'
                    linkFormat='button'
                    linkRegion={ title }
                  >
                    <button
                      type='button'
                      onClick={ _loadMore }
                      aria-label={`Load more ${ title }`}
                      className='inf-section__caro-more'
                      data-autotrack='false'
                    >
                      <div className='inf-section__caro-more-label'>
                        { loading ?
                          <>
                            <img src='/img/loader.gif' alt='Loading' /><br />
                            loading
                          </>
                        :
                          <>
                            see more
                          </>
                        }
                      </div>
                    </button>
                  </LinkTracker>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      { ((loop && isInteracted) || selectedIndex !== 0) &&
        <button
          className='carousel-content__arrow carousel-content__arrow--prev'
          type='button'
          onClick={ scrollPrev }
          aria-label='Previous Slide'
        >
          <ReactSVG src='/img/icons/arrow-hollow_left.svg' />
        </button>
      }
      { (loop || selectedIndex < items.length - 1) &&
        <button
          className='carousel-content__arrow carousel-content__arrow--next'
          type='button'
          onClick={ scrollNext }
          aria-label='Next Slide'
        >
          <ReactSVG src='/img/icons/arrow-hollow_left.svg' />
        </button>
      }
    </div>
  );
}

export default CarouselContent;
