import { Fragment } from 'react';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import SiteSettings from '~/siteSettings';
import { getIcon, min2secs } from '~/utils/utils';
import VideoPreview from '../videoPreview/videoPreview';
import LinkTracker from '~/components/linkTracker/linkTracker';

/**
 * Most Popular Block
 * @category Component
 * @extends Component
 * @summary Displays the content carousel block.
 * @prop {Object} content - The block content.
 * @return {Node} React node containing the most popular block.
 */

const BlockMostPopular = (props) => {
  const { content } = props;
  const items = content.field_content_list_nodes.slice(0, 6);

  return (
    <div className='x-hidden wrap-relative'>
      <div className='constrain'>
        <div className='most-popular'>
          <h2 className='carousel-content__heading'>
            popular now
          </h2>
          <div className='most-popular__container'>
            { items && items.map((slide, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`most-popular-caro-${ slide.nid }-${ i }`}>
                { slide?.path &&
                  <div className='card card--withnumber'>
                    <LinkTracker
                      destinationUrl={ slide.path }
                      linkCta={ slide.title.toLowerCase() }
                      linkFormat='tile'
                      linkRegion='popular now'
                      linkSubRegion={ i + 1 }
                    >
                      <Link href={ slide.path }>
                        <a
                          aria-label={`Visit ${ slide.title }`}
                          data-autotrack='false'
                        >
                          <div className='card__withnumber-block'>
                            <div className={`card__number card__number--${ i+1 }`}></div>
                            <div className='card__withnumber-img'>
                              { slide?.field_image?.field_image && slide.__typename === 'video' &&
                                <VideoPreview item={ slide }>
                                  <picture>
                                    <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ slide?.field_image?.field_image?.sm?.url } />
                                    <img
                                      src={ slide.field_image.field_image.lg.url }
                                      alt={ slide.field_image?.alt }
                                      width={ slide.field_image.field_image.lg.width }
                                      height={ slide.field_image.field_image.sm.height }
                                      className='card__img'
                                      loading='lazy'
                                    />
                                  </picture>
                                </VideoPreview>
                              }
                              { slide?.field_image?.field_image?.lg?.url && slide.__typename !== 'video' &&
                                <picture>
                                  <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ slide?.field_image?.field_image?.sm?.url } />
                                  <img
                                    src={ slide?.field_image?.field_image?.lg?.url }
                                    alt={ slide?.field_image?.alt }
                                    width={ slide?.field_image?.field_image?.width }
                                    height={ slide?.field_image?.field_image?.height }
                                    className='card__img'
                                    loading='lazy'
                                  />
                                </picture>
                              }
                              { slide.field_video_mpx_id && slide.field_video_mpx_id.duration !== undefined &&
                                <div className='block-videos__duration'>
                                  { min2secs(slide.field_video_mpx_id.duration) }
                                </div>
                              }
                            </div>
                          </div>
                          <div className='card__withnumber-block'>
                            <div></div>
                            <h3>
                              <div className='card__type-icon'>
                                <ReactSVG src={ getIcon(slide.__typename) } />
                              </div>
                              { slide.title }
                            </h3>
                          </div>
                        </a>
                      </Link>
                    </LinkTracker>
                  </div>
                }
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockMostPopular;
