import { Component } from 'react';
import Link from 'next/link';
import key from 'weak-key';
import { ReactSVG } from 'react-svg';
import { getIcon } from '~/utils/utils';
import SiteSettings from '~/siteSettings';

/**
 * See More Block
 * @category Component
 * @extends Component
 * @summary Displays a block which allows the loading of X addition items when clicked to display its entire data set.
 * @prop {Object} items - The block item's content.
 * @prop {int} seeMoreAt - At what point should these results paginate?
 * @return {Node} React node containing the see more block.
 */

class BlockSeeMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasShownMore: false
    };
  }

  seeMore = () => {
    this.setState({
      hasShownMore: true
    });
  }

  render() {
    const { items, seeMoreAt, children } = this.props;
    const { hasShownMore } = this.state;

    // Guard again nulls in content list
    const filteredItems = items.filter(item => item !== null);

    return (
      <div className='wrap-bg-primary'>
        <div className='constrain'>
          <div className='block-see-more'>
            <div className='block-see-more__heading'>
              { children }
            </div>
            <div className='block-see-more__grid'>
              {filteredItems.map((item, i) => (
                <Link href={ item.path } key={`see-more-item-${key(item)}`}>
                  <a aria-label={`Visit ${ item.title }`} className={ i >= seeMoreAt && !hasShownMore ? 'card-interior visuallyhidden' : 'card-interior'}>
                    <div className='placed'>
                      { item?.field_secondary_image?.field_image &&
                        <picture>
                          <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ item.field_secondary_image.field_image.seemore_sm.url } />
                          <img
                            src={ item.field_secondary_image.field_image.seemore_lg.url }
                            alt={ item?.title }
                            width={ item.field_secondary_image.field_image.seemore_lg.width }
                            height={ item.field_secondary_image.field_image.seemore_lg.height }
                            className='card-interior__img placed-elem'
                            loading='lazy'
                          />
                        </picture>
                      }
                      { item?.field_image?.field_image &&
                        <picture>
                          <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ item.field_image.field_image.seemore_sm.url } />
                          <img
                            src={ item.field_image.field_image.seemore_lg.url }
                            alt={ item?.title }
                            width={ item.field_image.field_image.seemore_lg.width }
                            height={ item.field_image.field_image.seemore_lg.height }
                            className='card-interior__img placed-elem'
                            loading='lazy'
                          />
                        </picture>
                      }

                      <div className='placed-50'></div>
                    </div>
                    <h2>
                      <div className='card-interior__type-icon'>
                        <ReactSVG src={ getIcon(item.__typename) } />
                      </div>
                      { item.title }
                    </h2>
                    <div className='card-interior__mask'></div>
                  </a>
                </Link>
              ))}
            </div>
            { !hasShownMore &&
              <button
                aria-label='See More'
                type='button'
                onClick={ this.seeMore }
                className='btn__cta'
              ><span>see more</span></button>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default BlockSeeMore;
