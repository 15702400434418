import { ReactSVG } from 'react-svg';
import { debounceTime } from 'rxjs/operators';
import useCaro from '~/hooks/useCaro';
import Store from '~/store/undux';
import { secondsToTimecode } from '~/utils/utils';

/**
 * Carousel Recommendations
 * @category Component
 * @extends Component
 * @summary Displays the content carousel block.
 * @prop {Object} playlist - The playlist.
 * @return {Node} React node containing the content carousel.
 */

const getDuration = duration => {
  const timecode = secondsToTimecode(duration).split(':');
  const HH = parseInt(timecode[0], 10) > 0 ? `${ parseInt(timecode[0], 10) }:` : '';
  const MM = parseInt(timecode[1], 10) > 0 ? `${ parseInt(timecode[1], 10) }` : '0';

  return `${ HH }${ MM }:${ timecode[2] }`;
}

const CarouselRecommendations = (props) => {
  const { playlist, store } = props;

  const { embla, viewportRef, scrollNext, scrollPrev, selectedIndex, setSelectedIndex } = useCaro({
    loop: true,
    align: 'start',
    slidesToScroll: 1
  });

  const playlistIndex = store.get('playlistIndex');
  const setPlaylistIndex = store.set('playlistIndex');


  const handleClick = (e) => {
    const index = parseInt(e.currentTarget.dataset.index, 10);

    if (embla.clickAllowed()) {
      // Set index for the carousel
      embla.scrollTo(index);

      // Set index in store
      if(store.get('playlistIndex') !== index) {
        setPlaylistIndex(index);
        setSelectedIndex(index);
      }
    }
  }

  // Observe playlistIndex so we can set caro index as well
  store
    .on('playlistIndex')
    .pipe(debounceTime(1))
    .subscribe(index => {
      setSelectedIndex(index);
      embla?.scrollTo?.(index);
    });

  // Ensure we always return an image
  const returnDefaultThumbnail = e => {
    e.target.src = '/img/recommendation_fpo.jpg';
  }

  return (
    <div className='caro-rec'>
      <div className='constrain'>
        <div className='caro-rec__viewport' ref={viewportRef}>
          <div className='caro-rec__container'>
            {playlist.map((slide, i) => (
              <button
                type='button'
                className='caro-rec__recomendation'
                key={`recomendation-item-${slide.id}`}
                onClick={ handleClick }
                data-index={ i }
              >
                <div className='caro-rec__recomendation-img'>
                  { slide.image_url &&
                    <img
                      src={ slide.image_url }
                      onError={ returnDefaultThumbnail }
                      alt={ slide.title }
                      loading='lazy'
                    />
                  }
                  { (i === playlistIndex || i === playlistIndex + 1 || (i === 0 && playlistIndex + 1 === playlist.length))
                    ? <>
                        { i === playlistIndex &&
                          <div className='caro-rec__badge caro-rec__nowplaying'>
                            Playing
                          </div>
                        }
                        { (i === playlistIndex + 1 || (i === 0 && playlistIndex + 1 === playlist.length)) &&
                          <div className='caro-rec__badge caro-rec__nextup'>
                            Up Next
                          </div>
                        }
                      </>
                    : <>
                        { slide.duration &&
                          <div className='block-videos__duration'>
                            { getDuration(slide.duration) }
                          </div>
                        }
                      </>
                  }
                </div>
                <h4>
                  { slide.title }
                </h4>
              </button>
            ))}
          </div>
        </div>
        <button
          className='caro-rec__arrow caro-rec__arrow--prev'
          type='button'
          onClick={ scrollPrev }
          aria-label='Previous Recommendation'
        >
          <ReactSVG src='/img/icons/arrow-hollow_left.svg' />
        </button>
        <button
          className='caro-rec__arrow caro-rec__arrow--next'
          type='button'
          onClick={ scrollNext }
          aria-label='Next Recommendation'
        >
          <ReactSVG src='/img/icons/arrow-hollow_left.svg' />
        </button>
      </div>
    </div>
  );
}

export default Store.withStore(CarouselRecommendations);
