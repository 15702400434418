import { Component } from 'react';
import { ReactSVG } from 'react-svg';

/**
 * Share
 * @category Component
 * @extends Component
 * @summary Displays the share component.
 * @prop {String} url - The URL to share.
 * @prop {String} title - The share title.
 * @prop {String} mediaUrl - The media url (Enables Pinterest).
 * @prop {bool} hasPrint - Should the print button be shown?
 * @return {Node} React node containing the share block.
 */

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  facebookShare = () => {
    const { url } = this.props;
    const shareUrl = url || window.location.href;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${ encodeURIComponent(shareUrl) }`,
      'FacebookShare',
      'menubar=no,location=no,resizable=yes,scrollbars=no,status=no,width=300,height=450'
    );
  }

  twitterShare = () => {
    const { url, title } = this.props;
    const shareUrl = url || window.location.href;
    const shareTitle = title || document.title;

    window.open(
      `https://twitter.com/intent/tweet?text=${ encodeURI(shareTitle) }&url=${ encodeURI(shareUrl) }`,
      'TwitterShare',
      'menubar=no,location=no,resizable=yes,scrollbars=no,status=no,width=320,height=450'
    );
  }

  pinterestShare = () => {
    const { url, title, mediaUrl } = this.props;
    const shareUrl = url || window.location.href;
    const shareTitle = title || document.title;

    window.open(
      `http://pinterest.com/pin/create/button/?url=${ encodeURIComponent(shareUrl) }&media=${ encodeURIComponent(mediaUrl) }&description=${ encodeURIComponent(shareTitle) }`,
      'PinterestShare',
      'menubar=no,location=no,resizable=yes,scrollbars=no,status=no,width=300,height=450'
    );
  }

  print = () => {
    window.print();
  }

  render() {
    const { hasPrint, mediaUrl } = this.props;

    return (
      <div className='share'>
        { hasPrint &&
          <button type='button' onClick={ this.print } className='share__social-btn' aria-label='Share'>
            <ReactSVG src='/img/icons/print.svg' />
          </button>
        }
        <button type='button' onClick={ this.facebookShare } className='share__social-btn' aria-label='Share to Facebook'>
          <ReactSVG src='/img/icons/facebook.svg' />
        </button>
        <button type='button' onClick={ this.twitterShare } className='share__social-btn' aria-label='Share to Twitter'>
          <ReactSVG src='/img/icons/twitter.svg' />
        </button>
        { mediaUrl &&
          <button type='button' onClick={ this.pinterestShare } className='share__social-btn' aria-label='Share to Pinterest'>
            <ReactSVG src='/img/icons/pinterest.svg' />
          </button>
        }
      </div>
    );
  }
}

export default Share;
