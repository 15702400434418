import { useEffect, useState, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import Link from 'next/link';
import key from 'weak-key';
import Category from '~/components/category/category';
import SiteSettings from '~/siteSettings';
import { getIcon } from '~/utils/utils';
import LinkTracker from '~/components/linkTracker/linkTracker';
import useCaro from '~/hooks/useCaro';

/**
 * Featured Carousel Block
 * @category Component
 * @extends Component
 * @summary Displays the site featured carousel block.
 * @prop {Object} blockItem - The block item's content.
 * @return {Node} React node containing the featured carousel.
 */

const FeaturedCarousel = props => {
  const { blockItem } = props;
  const [timerActive, setTimerActive] = useState(false);
  const timer = useRef(null);
  const items = blockItem?.field_view?.resultRow;
  const { embla, viewportRef, scrollNext, scrollTo, selectedIndex, onSelect } = useCaro({
    loop: true,
    align: 'start',
    slidesToScroll: 1
  });

  const clearTimer = () => {
    if(timer.current) {
      setTimerActive(false);
      clearInterval(timer.current);
      timer.current = null;
    }
  }

  useEffect(() => {
    if (!embla) return;

    // Set First Index
    onSelect();

    // Attach Events
    embla.on('select', onSelect);

    // Timer
    setTimerActive(true);
    timer.current = setInterval(() => {
      scrollNext();
    }, 10000);
  }, [onSelect, embla, timer, scrollNext]);

  return (
    <>
      <div className='constrain'>
        <h2 className='carousel-content__heading'>
          { blockItem?.field_display_title }
        </h2>
      </div>
      <div className='carousel-featured'>
        <div className='constrain'>
          <div className='carousel-featured__viewport' ref={ viewportRef }>
            <div className='carousel-featured__container'>
              {items && items.map((slide, i) => (
                <div key={`featured-item-${ key(slide) }`} className='carousel-featured__slide carousel-featured__grid'>
                  <div className='carousel-featured__cta'>
                    <LinkTracker
                      destinationUrl={ slide.path }
                      linkCta={ slide.title }
                      linkFormat='image'
                      linkRegion={ blockItem?.field_display_title }
                      linkSubRegion={`${i + 1} - featured cta`}
                    >
                      <Link href={ slide.path }>
                        <a
                          aria-label={`Visit ${ slide.title }`}
                          onMouseDown={ clearTimer }
                          onTouchStart={ clearTimer }
                          role='button'
                          tabIndex={ i }
                          data-autotrack='false'
                        >
                          <div className='play-icon'>
                            <ReactSVG src='/img/icons/play.svg' />
                          </div>
                          <picture>
                            <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ slide.field_image.field_image.featured_sm.url } />
                            <img
                              width={ slide.field_image.field_image.featured_lg.width }
                              height={ slide.field_image.field_image.featured_lg.height }
                              src={ slide.field_image.field_image.featured_lg.url }
                              alt={ slide.field_image?.alt }
                              className='carousel-featured__img'
                            />
                          </picture>
                        </a>
                      </Link>
                    </LinkTracker>
                  </div>
                  <div className='carousel-featured__slide-copy'>
                    <ReactSVG src={ getIcon(slide.__typename) } />
                    <h2>{ slide.title }</h2>
                    <Category
                      trackData={{
                        link: slide.field_category.path,
                        linkCta: slide.field_category.name,
                        linkFormat: 'text',
                        linkRegion: blockItem?.field_display_title,
                        linkSubRegion: `${i + 1} - kicker`
                      }}
                      hideShare
                      content={ slide }
                    />
                    <p>{ slide.field_subhead }</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='carousel-featured__grid'>
            <div></div>
            <div className='place-relative'>
              <div className='carousel-featured__pager'>
                {items && items.map((page, i) => (
                  <button
                    aria-label={`Select Slide #${ i }`}
                    key={`slide-item-${key(page)}`}
                    type='button'
                    className={ selectedIndex === i ? (timerActive ? 'pager-active' : 'pager-active pager-active--no-anim') : '' }
                    onClick={() => scrollTo(i)}
                    data-slide={ i }
                  ></button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturedCarousel;
