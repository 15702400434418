import { Fragment, useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import SiteSettings from '~/siteSettings';
import { getIcon, min2secs } from '~/utils/utils';
import VideoPreview from '../videoPreview/videoPreview';
import CarouselContent from '~/components/carouselContent/carouselContent';
import LinkTracker from '~/components/linkTracker/linkTracker';
import useIsMobile from '~/hooks/useIsMobile';

/**
 * Most Popular Block
 * @category Component
 * @extends Component
 * @summary Displays the content carousel block.
 * @prop {Object} content - The block content.
 * @return {Node} React node containing the content carousel.
 */

const BlockSeeAll = (props) => {
  const { content } = props;
  const isMobile = useIsMobile();
  const items = content.field_display_as_view.resultRow.slice(0, 4);

  const getHeading = () => {
    return(
      <h2 className='see-all__heading'>
        <span>
          { content.field_display_title || content.info }
        </span>
        { content?.field_has_terms?.[0]?.path &&
          <LinkTracker
            destinationUrl={ content?.field_has_terms?.[0]?.path }
            linkCta='see all'
            linkFormat='text'
            linkRegion={ content.field_display_title }
            linkSubRegion='heading'
          >
            <Link href={ content?.field_has_terms?.[0]?.path } passHref>
              <a
                aria-label={`Visit ${ content?.field_has_terms?.[0]?.name }`}
                className='see-all__cta'
                data-autotrack='false'
              >
                see all
                <ReactSVG src='/img/icons/see-all-arrow.svg' />
              </a>
            </Link>
          </LinkTracker>
        }
      </h2>
    );
  }

  return (
    <div className='x-hidden wrap-relative'>
      { isMobile ?
        <div className='inf-section-caro'>
          <CarouselContent
            items={ items }
            title={ content.field_display_title || content.info }
            headingOveride={ getHeading }
          />
        </div>
      :
        <div className='constrain'>
          <div className='see-all'>
            { getHeading() }
            <div className='see-all__container'>
              { items && items.map((slide, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`see-all-caro-${ slide.nid }-${ i }`}>
                  { slide?.path &&
                    <div className='card'>
                      <LinkTracker
                        destinationUrl={ slide.path }
                        linkCta={ slide.title.toLowerCase() }
                        linkFormat='tile'
                        linkRegion={ content.field_display_title }
                        linkSubRegion={`carousel - ${i + 1}`}
                      >
                        <Link href={ slide.path }>
                          <a
                            aria-label={`Visit ${ slide.title }`}
                            data-autotrack='false'
                          >
                            <div className='placed'>
                              { slide?.field_image?.field_image && slide.__typename === 'video' &&
                                <VideoPreview item={ slide }>
                                  <picture>
                                    <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ slide.field_image.field_image.sm.url } />
                                    <img
                                      src={ slide.field_image.field_image.lg.url }
                                      alt={ slide.field_image?.alt }
                                      width={ slide.field_image.field_image.lg.width }
                                      height={ slide.field_image.field_image.sm.height }
                                      className='card__img placed-elem'
                                      loading='lazy'
                                    />
                                  </picture>
                                </VideoPreview>
                              }
                              { (slide.field_image || slide.field_secondary_image) && slide.__typename !== 'video' &&
                                <picture>
                                  <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ slide.field_secondary_image ? slide.field_secondary_image.field_image.field_image.sm.url : slide.field_image.field_image.sm.url } />
                                  <img
                                    src={ slide.field_secondary_image ? slide.field_secondary_image.field_image.field_image.lg.url : slide.field_image.field_image.lg.url }
                                    alt={ slide.field_secondary_image ? slide.field_secondary_image.field_image?.alt : slide.field_image?.alt }
                                    width={ slide.field_secondary_image ? slide.field_secondary_image.field_image.field_image.width : slide.field_image.field_image.width }
                                    height={ slide.field_secondary_image ? slide.field_secondary_image.field_image.field_image.height : slide.field_image.field_image.height }
                                    className='card__img placed-elem'
                                    loading='lazy'
                                  />
                                </picture>
                              }
                              <div className='placed-275x154'></div>
                              { slide.field_video_mpx_id && slide.field_video_mpx_id.duration !== undefined &&
                                <div className='block-videos__duration'>
                                  { min2secs(slide.field_video_mpx_id.duration) }
                                </div>
                              }
                            </div>
                            <h3>
                              <div className='card__type-icon'>
                                <ReactSVG src={ getIcon(slide.__typename) } />
                              </div>
                              { slide.title }
                            </h3>
                          </a>
                        </Link>
                      </LinkTracker>
                    </div>
                  }
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default BlockSeeAll;
