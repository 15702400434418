import key from 'weak-key';
import BlockCard from '~/components/blockCard/blockCard';
import BlockFeaturedComedians from '~/components/blockFeaturedComedians/blockFeaturedComedians';
import BlockHeroVideo from '~/components/blockHeroVideo/blockHeroVideo';
import BlockScheduled from '~/components/blockScheduled/blockScheduled';
import BlockSeeMore from '~/components/blockSeeMore/blockSeeMore';
import BlockVideos from '~/components/blockVideos/blockVideos';
import BlockPlugger from '~/components/blockPlugger/blockPlugger';
import CarouselContent from '~/components/carouselContent/carouselContent';
import CarouselContentWithFeature from '~/components/carouselContentWithFeature/carouselContentWithFeature';
import ImageHero from '~/components/imageHero/imageHero';
import NewsletterSignup from '~/components/newsletterSignup/newsletterSignup';
import VirtualAudience from '~/components/virtualAudience/virtualAudience';
import VideoBlock from '~/components/videoBlock/videoBlock';
import WhereToWatchBlock from '~/components/whereToWatchBlock/whereToWatchBlock';
import InfiniteGrid from '~/components/infiniteGrid/infiniteGrid';
import BlockSeeAll from '~/components/blockSeeAll/blockSeeAll';
import BlockMostPopular from '~/components/blockMostPopular/blockMostPopular';
import BlockFeaturedCarousel from '~/components/blockFeaturedCarousel/blockFeaturedCarousel';

/**
 * Block Parser
 * @category Functional Component
 * @summary A catch all for all custom blocks, pair this with the getBlocksFromQueue data util for block based glory.
 * @prop {Object} blocks - The block items.
 * @prop {Object} drupalSettings - The drupal settings.
 * @prop {Object} recommendations - The recomentations for featured videos settings.
 * @return {Node} React node containing the blocks.
 */

const BlockParser = props => {
  const { blockItems, drupalSettings, recommendations } = props;

  return (
    <>
      {blockItems.map((blockItem) =>
        <div key={`block-item-${key(blockItem)}`}>
          {/* Homepage Hero */}
          { blockItem.field_theme_placement === 'home_hero' &&
            <ImageHero blockItem={ blockItem } />
          }
          {/* Block Scheduled */}
          { blockItem.field_theme_placement === 'block_scheduled' && blockItem &&
            <BlockScheduled blockItem={ blockItem } />
          }
          {/* Block See More */}
          { blockItem.field_theme_placement === 'block_see_more' && blockItem.field_content_list_nodes &&
            <BlockSeeMore
              items={ blockItem.field_content_list_nodes }
              title={ blockItem.info }
              seeMoreAt={ 2 }
            >
              <img
                src='/img/drew_heart.png'
                alt={ blockItem.info }
                width='50'
                height='53'
                loading='lazy'
              />
              <h3>{ blockItem.info }</h3>
            </BlockSeeMore>
          }
          {/* Block Content Carousel */}
          { blockItem.field_theme_placement === 'carousel_content' && (blockItem?.field_content_list_nodes || blockItem?.field_display_as_view?.resultRow) &&
            <CarouselContent
              items={ blockItem?.field_content_list_nodes || blockItem?.field_display_as_view?.resultRow }
              title={ blockItem.info }
              loop={ blockItem.field_loop }
            />
          }
          {/* Block Content Carousel with Feature */}
          { blockItem.field_theme_placement === 'carousel_content_with_feature' && (blockItem?.field_scheduled_block?.resultRow[0]?.field_content_list_nodes || blockItem?.field_display_as_view?.field_content_list_nodes || blockItem?.field_content_list_nodes) &&
            <CarouselContentWithFeature
              content={ blockItem }
              overrideClass={ blockItem.field_override_class || '' }
              loop={ blockItem.field_loop }
            />
          }
          {/* Block Videos */}
          { blockItem.field_theme_placement === 'block_videos' && (blockItem?.field_content_list_nodes || blockItem?.field_display_as_view?.resultRow) &&
            <BlockVideos
              items={ blockItem?.field_display_as_view?.resultRow }
              title={ blockItem.info }
            />
          }
          {/* Mapp Block */}
          { blockItem.field_theme_placement === 'newsletter_block' &&
            <NewsletterSignup />
          }
          {/* Virtual Audience */}
          { blockItem.field_theme_placement === 'virtual_audience' &&
            <VirtualAudience />
          }
          {/* Where To Watch */}
          { blockItem.field_theme_placement === 'where_to_watch' &&
            <WhereToWatchBlock />
          }
          {/* Ads */}
          { blockItem.field_theme_placement === 'ad_unit' &&
            <>
              { !blockItem.field_is_incremental_ ?
                <div
                  data-ad-ref={ blockItem.field_unit }
                  className='bidbarrel-ad'
                ></div>
                :
                <div
                  data-ad-ref={ blockItem.field_unit }
                  data-incremental={ blockItem.field_increment }
                  className='bidbarrel-ad'
                ></div>
              }
            </>
          }
          {/* Plugs */}
          { blockItem.field_theme_placement === 'plugger_forms' &&
            <BlockPlugger blockItem={ blockItem } />
          }
          {/* Video Player */}
          { (blockItem.field_theme_placement === 'featured_video' && blockItem.field_content_list_nodes && blockItem.field_content_list_nodes[0]) &&
            <VideoBlock
              data={ blockItem.field_content_list_nodes[0] }
              drupalSettings={ drupalSettings }
              recommendations={ recommendations }
            />
          }
          {/* Infinite Grid */}
          { blockItem.field_theme_placement === 'infinite_grid' &&
            <InfiniteGrid content={ blockItem } />
          }
          {/* Hero Video Blocks */}
          { blockItem.field_theme_placement === 'video_hero' && (blockItem.resultRow || blockItem.field_scheduled_block.resultRow) &&
            <BlockHeroVideo blockItem={ blockItem } />
          }
          {/* Card Blocks */}
          { blockItem.field_theme_placement === 'block_card' &&
            <BlockCard content={ blockItem } />
          }
          {/* Featured Comedians Blocks */}
          { blockItem.field_theme_placement === 'featured_comedians' &&
            <BlockFeaturedComedians content={ blockItem } />
          }
          {/* See All Blocks */}
          { blockItem.field_theme_placement === 'see_all' &&
            <BlockSeeAll content={ blockItem } />
          }
          {/* Most Popular Blocks */}
          { blockItem.field_theme_placement === 'most_popular' &&
            <BlockMostPopular content={ blockItem } />
          }
          {/* Featured Carousel Block */}
          { blockItem.field_theme_placement === 'featured_carousel' &&
            <BlockFeaturedCarousel blockItem={ blockItem } />
          }
        </div>
      )}
    </>
  );
}

export default BlockParser;
