import Link from 'next/link';
import Share from '~/components/share/share';
import LinkTracker from '~/components/linkTracker/linkTracker';

/**
 * Category
 * @category Functional Component
 * @extends Component
 * @summary Renders the category component.
 * @prop {object} content - The components content.
 * @prop {bool} hideShare - If set, will hide the share block.
 * @prop {object} trackData - LinkTracking attributes.
 * @return {Node} React node containing rendered component.
 */

const Category = (props) => {
  const { content, hideShare, trackData } = props;
  const GetLink = linkContent => {
    const category = (
      <div className='category'>
        <Link href={ linkContent.field_category.path }>
          { trackData
            ?
              <a data-autotrack='false'>{ linkContent.field_category.name }</a>
            :
              <>{ linkContent.field_category.name }</>
          }
        </Link>
      </div>
    );

    if ( trackData ) {
      return (
        <LinkTracker {...trackData}>
          { category }
        </LinkTracker>
      );
    }

    return (
      category
    );
  }

  return (
    <div className='category-grid'>
      {/* If URL */}
      { content.field_category && content.field_category.path &&
        <>
          { GetLink(content) }
        </>
      }
      {/* If no URL */}
      { content.field_category && content.field_category.path === undefined &&
        <>
          <div className='category'>
            { content.field_category.name }
          </div>
        </>
      }
      { !hideShare &&
        <div className='category__share mobile-only'>
          <Share
            title={ content.title }
          />
        </div>
      }
    </div>
  );
}

export default Category;
