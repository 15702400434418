import { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import key from 'weak-key';
import { ReactSVG } from 'react-svg';
import { request } from 'graphql-request';
import { min2secs, getIcon, isGiveAwayLink } from '~/utils/utils';
import VideoPreview from '../videoPreview/videoPreview';
import CarouselContent from '~/components/carouselContent/carouselContent';
import LinkTracker from '~/components/linkTracker/linkTracker';
import useIsMobile from '~/hooks/useIsMobile';

// GQL
import GET_VIEW_RESULTS from '~/graphql/GET_VIEW_RESULTS.graphql';

/**
 * Infinite Grid
 * @category Functional Component
 * @summary Displays an infinite grid.
 * @prop {object} content - The blocks content.
 * @return {Node} React node containing an infinite grid.
 */

const InfiniteGrid = props => {
  const { content } = props;
  const [data, setData] = useState(content?.field_view?.resultRow || content?.field_display_as_view?.resultRow);
  const [offset, setOffset] = useState(0);
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const tick = useRef(false);

  const getMore = async () => {
    setLoading(true);
    let newData = [];
    const newOffset = offset + 12;

    let viewArgs = null;
    if(content?.field_has_terms) {
      viewArgs = ['article,video,gallery,recipe,episode', content?.field_has_terms.map(term => term.tid).join(',')]
    }

    const pageData = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_VIEW_RESULTS, {
      machine_name: content?.field_view?.id || content?.field_display_as_view?.id,
      display: 'master',
      itemsPerPage: 12,
      offset: newOffset,
      ...(viewArgs && { viewArgs })
    });

    setLoading(false);

    if(pageData.viewQuery.resultRow.length) {
      newData = [
        ...data,
        ...pageData.viewQuery.resultRow
      ];

      setData(newData);
    }

    setOffset(newOffset);
  }

  if(data) {
    return (
      <div className={ isMobile ? 'constrain constrain--nopadd' : 'constrain'}>
        { isMobile ?
          <div className='inf-section-caro'>
            <CarouselContent
              items={ data }
              title={ content.field_display_title }
              hasLoadMore
              loading={ loading }
              loadMore={ getMore }
              count={ content?.field_view?.count || content?.field_display_as_view?.count }
              hideTypeIcons
            />
          </div>
          :
          <div className='inf-section'>
            <h2>{ content.field_display_title }</h2>
            <div className='inf-section__grid'>
              {data.map((item, i) => (
                <>
                  { item?.path &&
                    <LinkTracker
                      destinationUrl={ item.path }
                      linkCta={ item.title }
                      linkFormat='tile'
                      linkRegion={ content.field_display_title.toLowerCase() }
                      linkSubRegion={ i + 1 }
                      key={`inf-item-${key(item)}`}
                    >
                      <Link href={ item.path } passHref>
                        <a data-autotrack='false' target={ isGiveAwayLink(item?.path) ? '_blank' : '_self'}>
                          <div className='inf-section__item' >
                            <div className='inf-section__img'>
                              { item.field_video_mpx_id && item.field_video_mpx_id.duration !== undefined &&
                                <div className='block-videos__duration'>
                                  { min2secs(item.field_video_mpx_id.duration) }
                                </div>
                              }
                              { item?.field_image?.field_image &&
                                <VideoPreview item={ item }>
                                  <picture>
                                    <source media='(max-width: 768px)' srcSet={ item.field_image.field_image.sm.url } />
                                    <img
                                      loading='lazy'
                                      className='img-full'
                                      src={ item.field_image.field_image.lg.url }
                                      width={ item.field_image.field_image.lg.width }
                                      height={ item.field_image.field_image.lg.height }
                                      alt={ item.field_image?.alt }
                                    />
                                  </picture>
                                </VideoPreview>
                              }
                            </div>
                            <h4>
                              { content.field_display_title === 'Most Recent' &&
                                <div className='card__type-icon'>
                                  <ReactSVG src={ getIcon(item.__typename) } />
                                </div>
                              }
                              { item.title }
                            </h4>
                          </div>
                        </a>
                      </Link>
                    </LinkTracker>
                  }
                </>
              ))}
            </div>
            { (content?.field_view?.count || content?.field_display_as_view?.count) > data.length &&
              <div className='inf-section__caro-more-desktop'>
                <div className='text-center'>
                  <LinkTracker
                    destinationUrl=''
                    linkCta='see more'
                    linkFormat='button'
                    linkRegion={ content.field_display_title.toLowerCase() }
                    linkSubRegion='see more'
                  >
                    <button
                      type='button'
                      onClick={ getMore }
                      aria-label={`Load more ${ content.field_display_title } videos`}
                      className='btn__cta'
                      data-autotrack='false'
                    >
                      <span>
                        { loading ?
                          <>
                            <img src='/img/loader.gif' alt='Loading' />
                            loading
                          </>
                        :
                          <>
                            see more
                          </>
                        }
                      </span>
                    </button>
                  </LinkTracker>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }

  return false;
}

export default InfiniteGrid;
