import { useState } from 'react';
import Link from 'next/link';
import key from 'weak-key';
import * as moment from 'moment';
import { useSwipeable } from 'react-swipeable'
import { getFormattedSchedule } from '~/utils/utils';
import SiteSettings from '~/siteSettings';

/**
 * Scheduled Block
 * @category Functional Component
 * @summary Displays a section hero.
 * @prop {Object} blockItem - The block item's content.
 * @return {Node} React node containing the weekly schedule.
 */

const BlockScheduled = props => {
  const { blockItem } = props;
  const data = blockItem.field_scheduled_block.resultRow[0].field_content_list_nodes[0];
  const schedule = {
    monday: data.monday || {},
    tuesday: data.tuesday || {},
    wednesday: data.wednesday || {},
    thursday: data.thursday || {},
    friday: data.friday || {}
  };
  const [formattedSchedule] = useState(getFormattedSchedule(schedule));
  const offset = moment().isDST() ? -420 : -480;
  const dayNow = moment().utcOffset(offset).format('M/D').toLowerCase();
  const currentDayIndex = formattedSchedule.findIndex((elem) => elem && elem.displayDate === dayNow);
  const [currentEpisode, setIsCurrentEpisode] = useState(currentDayIndex !== -1 ? currentDayIndex : 0);
  const [currentGuest, setCurrentGuest] = useState(0);

  // Methods

  const selectEpisode = (e) => {
    setIsCurrentEpisode(parseInt(e.currentTarget.dataset.episode, 10));
    setCurrentGuest(0);
  }

  const selectGuest = (e) => {
    setCurrentGuest(parseInt(e.currentTarget.dataset.guest, 10));
  }

  // Swipable

  const nextGuest = () => {
    const next = currentGuest < formattedSchedule[currentEpisode].field_show_lineup.length - 1
      ? currentGuest + 1
      : 0;

    setCurrentGuest(next);
  }

  const prevGuest = () => {
    const prev = currentGuest > 0
      ? currentGuest - 1
      : formattedSchedule[currentEpisode].field_show_lineup.length - 1;

      setCurrentGuest(prev);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      nextGuest()
    },
    onSwipedRight: () => {
      prevGuest()
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className='wrap-bg-primary'>
      <div className='constrain'>
        <div className='block-schedule'>
          <h2 className='block-schedule__heading'>
            { blockItem.info }
          </h2>
          <div className='block-schedule__grid'>
            {formattedSchedule.map((episode, i) => {
              if(episode) {
                return(
                  <div key={`episode-selector-${key(episode)}`}>
                    <h3 className='block-schedule__item-mobile-label'>
                      { episode.mobileLabel }
                    </h3>
                    <button
                      type='button'
                      className='block-schedule__item'
                      onClick={ selectEpisode }
                      key={`schedule-item-${key(episode)}`}
                      data-episode={ i }
                    >
                      { episode?.field_image?.field_image?.lg?.url &&
                        <picture>
                          <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ episode.field_image.field_image.sm.url } />
                          <img
                            src={ episode.field_image.field_image.lg.url }
                            alt={ episode.field_image?.alt }
                            width={ episode.field_image.field_image.lg.width }
                            height={ episode.field_image.field_image.lg.height }
                            className={ `block-schedule__img${ i === currentEpisode ? ' block-schedule__img--active' : '' }` }
                          />
                        </picture>
                      }
                      <div className={`block-schedule__overlay${ i === currentEpisode ? ' block-schedule__overlay--active' : '' }`}>
                        <h3>{ episode.label }</h3>
                      </div>
                    </button>
                  </div>
                );
              }

              return false;
            })}
          </div>
          { formattedSchedule[currentEpisode] &&
            <div className='block-schedule__episode'>
              <div className='block-schedule__content'>
                <h2>
                  { formattedSchedule[currentEpisode].label }
                </h2>
                { formattedSchedule[currentEpisode].field_show_lineup &&
                  <ul>
                    {formattedSchedule[currentEpisode].field_show_lineup.map((item) => (
                      <li key={`lineup-text-${key(item)}`}>
                        { item.title }
                      </li>
                    ))}
                  </ul>
                }
                <div dangerouslySetInnerHTML={{__html: formattedSchedule[currentEpisode].body}} className='block-schedule__copy'></div>
                <div className='spacer--2'></div>
                <div className='spacer--2'></div>
                <Link href={ formattedSchedule[currentEpisode].path }>
                  <a
                    title={ `See More ${ formattedSchedule[currentEpisode].label }` }
                    aria-label='See More'
                    className='btn__cta'
                  ><span>see more</span></a>
                </Link>
                <div className='spacer--2'></div>
              </div>
              { formattedSchedule[currentEpisode].field_show_lineup &&
                <div className='block-schedule__guests' {...handlers}>
                  {formattedSchedule[currentEpisode].field_show_lineup.map((item, i) => (
                    <div key={`lineup-${key(item)}`}>
                      <div key={`lineup-${key(item)}`}>
                        { item?.field_image?.field_image?.guest?.url &&
                          <div className={`block-schedule__guest ${ i === currentGuest ? ' block-schedule__guest--active' : '' }`}>
                            <img
                              src={ item.field_image.field_image.guest.url }
                              alt={ item.field_image?.alt }
                              width={ item.field_image.field_image.guest.width }
                              height={ item.field_image.field_image.guest.height }
                              loading='lazy'
                            />
                            <h5>{ item.title }</h5>
                            <div className='block-schedule__guest-overlay'></div>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                  { formattedSchedule[currentEpisode].field_show_lineup.length > 1 &&
                    <div className='block-schedule__guest-transports'>
                      {formattedSchedule[currentEpisode].field_show_lineup.map((item, i) => (
                        <button
                          aria-label={`Select item ${ i + 1 }`}
                          key={`transport-${key(item)}`}
                          onClick={ selectGuest }
                          data-guest={ i }
                          type='button'
                          className={`block-schedule__guest-transport ${ i === currentGuest ? ' block-schedule__guest-transport--active' : '' }`}
                        ></button>
                      ))}
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default BlockScheduled;
