import { createRef, Component } from 'react';
import { throttle } from 'lodash';
import { ReactSVG } from 'react-svg';
import { supportsPassive, verifyVarIsTruthy } from '~/utils/utils';

/**
 * Stick
 * @category Component
 * @extends Component
 * @summary Sticks stuff to the bottom right of the page.
 * @return {Node} React node containing the sticky stuff.
 */

class Stick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStuck: false,
      videoTop: null,
      userClosed: false
    };
    this.elem = createRef();
    this.throttleScroll = throttle(() => {
      this.checkInView();
    }, 100);
  }

  componentDidMount() {
    this.setVideoTopAndScrollListener();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttleScroll, true);
  }

  setVideoTopAndScrollListener = async () => {
    // The player container does not have a height on the initial render.
    // Wait until it's natural height is set before starting calculations.
    await verifyVarIsTruthy(20, 50, this.elem.current.clientHeight);

    this.setState({
      videoTop: this.elem.current.getBoundingClientRect().top + window.scrollY + (this.elem.current.clientHeight / 2)
    });

    this.checkInView();
    window.addEventListener('scroll', this.throttleScroll, supportsPassive ? { passive: true } : false);
  }

  checkInView = () => {
    const { isStuck, videoTop, userClosed } = this.state;

    if(!userClosed) {
      if (window.scrollY > videoTop && !isStuck) {
        this.setState({
          isStuck: true
        });
      } else if (window.scrollY < videoTop && isStuck) {
        this.setState({
          isStuck: false
        });
      }
    }
  }

  close = () => {
    this.setState({
      isStuck: false,
      userClosed: true
    });
  }

  render() {
    const { isStuck } = this.state;
    const { children } = this.props;

    return (
      <>
        <div className={ isStuck ? 'stuck-placeholder' : null }></div>
        <div ref={ this.elem } className={`sticky ${ isStuck ? 'stuck' : null }`}>
          <button
            type='button'
            onClick={ this.close }
            className='stuck-close'
            aria-label='close sticky player'
          >
            <ReactSVG src='/img/icons/close.svg' />
          </button>
          { children }
        </div>
      </>
    );
  }
}

export default Stick;
