import { Component } from 'react';

/**
 * PageWrapper
 * @category Higher Order Component
 * @extends Component
 * @summary Main page wrapper, handles transitions.
 * @param {Component} Page The page to render.
 * @param {Component} vars Any additional vars the page sends along.
 * @return {Node} React node containing the site frame.
 */

export default function pageWrapper(Page, vars) {
  return class PageWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.vars = vars || {};
    }

    render() {
      return (
        <div className='wrap-site'>
          <Page {...this.props} />
        </div>
      );
    }
  };
}
