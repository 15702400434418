import { createRef, Component } from 'react';
import { ReactSVG } from 'react-svg';
import { request } from 'graphql-request';

const ADD_SUBSCRIPTION = `
  mutation(
    $email: String!,
    $first_name: String,
    $last_name: String,
    $birth_date: Int,
    $address: String,
    $city: String,
    $state: String,
    $postal_code: Int,
    $country: String,
    $welcome_letter: Boolean,
    $acquisition_source: String
  ) {
    mappMutation(
      email: $email,
      first_name: $first_name,
      last_name: $last_name,
      birth_date: $birth_date,
      address: $address,
      city: $city,
      state: $state,
      postal_code: $postal_code,
      country: $country,
      welcome_letter: $welcome_letter,
      acquisition_source: $acquisition_source
    ) {
      code
      status
      message
    }
  }
`;

/**
 * NewsletterSignup
 * @category Component
 * @extends Component
 * @summary Displays and provides functionality for the newsletter signup form.
 * @return {Node} React node containing the newsletter signup box.
 */

class NewsletterSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      success: false,
      message: ''
    };
    this.trackTimer = null;
    this.form = createRef();
  }

  componentWillUnmount() {
    // Clear recursive check for window.om if its still churning
    if(this.trackTimer) {
      clearTimeout(this.trackTimer);
    }
  }

  handleEmailChange = e => {
    this.setState({
      email: e.target.value
    });
  }

  // Submit form on enter intercepting default form submit provided by most browsers
  handleEmailKeypress = e => {
    if(e.charCode === 13) {
      e.preventDefault();
      this.handleSubmit();
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;

    if(this.form.current.reportValidity()) {
      // Get Data
      const res = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', ADD_SUBSCRIPTION, {
        email
      });

      if(res.mappMutation.code === 220 || res.mappMutation.code === 201 || res.mappMutation.code === 200) {
        this.setState({
          message: 'You have successfully signed up for Drew\'s News!',
          success: true
        });

        this.trackSubmit();
      }
    }
  }

  trackSubmit = () => {
    if(window.om.trackAction) {
      window.om.trackAction('trackNewsletterSubmit');
    } else {
      // If we don't have window.om let's recursivly check for it
      this.trackTimer = setTimeout(() => {
        this.trackSubmit();
      }, 200);
    }
  }

  render() {
    const { email, success, message } = this.state;

    return(
      <div className='wrap-bg-primary'>
        <div className='constrain'>
          { !success ?
            <form ref={ this.form }>
              <div className='newsletter'>
                <h2>don&apos;t snooze on Drew&apos;s News!</h2>
                <p>Spread joy with guest updates, exclusive events and more sent directly to your inbox.</p>
                <div className='newsletter__input'>
                  <input
                    aria-label='Email'
                    className='newsletter__input-text'
                    type='email'
                    required
                    value={ email }
                    placeholder='Email address'
                    onChange={ this.handleEmailChange }
                    onKeyPress={ this.handleEmailKeypress }
                  />
                  <button
                    type='submit'
                    onClick={ this.handleSubmit }
                    className='newsletter_frm-submit'
                    aria-label='Sign Up'
                  >
                    sign up
                  </button>
                </div>
                <div className='newsletter__rules'>
                  <label htmlFor='agree' className='newsletter_frm-checkbox'>
                    <div className='newsletter_frm-checkbox-input'>
                      <input type='checkbox' name='agree' id='agree' required />
                    </div>
                    <div className='newsletter_frm-checkbox-copy'>
                      I agree to receive the Drew Barrymore Show newsletters, which may include personalized offers from the Drew Barrymore Show, their affiliates and their respective advertising partners. I agree to the CBS Interactive <a href='https://cbsinteractive.com/legal/cbsi/terms-of-use/' target='_blank' rel='noopener noreferrer'>Terms of Use</a>, and acknowledge the data collection and usage practices outlined in CBS Interactive&apos;s <a href='https://www.viacomcbsprivacy.com/policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>. I understand that I can manage my newsletter subscriptions at any time by unsubscribing directly from a link in the newsletter.
                    </div>
                  </label>
                </div>
              </div>
            </form>
          :
            <div className='newsletter__notice'>
              <ReactSVG src='/img/icons/success.svg' /> { message }
            </div>
          }
        </div>
      </div>
    );
  }
}

export default NewsletterSignup;
