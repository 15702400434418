/* global om */
/* eslint @next/next/no-sync-scripts: "off" */
import Head from 'next/head';
import { useEffect } from 'react';
import { verifyOm } from '~/utils/utils';

/**
 * Skimlinks
 * @category Functional Component
 * @summary Display the skimlinks disclaimer and includes the JS lib.
 * @prop {object} data - The page data.
 * @prop {object} type - The disclaimer type.
 * @return {Node} React node containing the skimlinks disclaimer.
 */
const Skimlinks = props => {
  const { data, type } = props;
  const disclaimer = data.skimlinksQuery[type];

  const fireTracking = async e => {
    // Prepares data array from link attributes.
    const skimlinkData = {
      destinationUrl: e.currentTarget.href ?? null,
    };

    // Check for set tracking attributes.
    if (e?.currentTarget?.dataset) {
      const cta = e?.currentTarget?.title ? e.currentTarget.title : e.currentTarget.text;
      const { linkFormat, linkRegion, store, linkSubregion } = e.currentTarget.dataset;

      skimlinkData.linkCta = cta?.toLowerCase?.() || null;
      skimlinkData.linkFormat = linkFormat?.toLowerCase?.() || null;
      skimlinkData.linkRegion = linkRegion?.toLowerCase?.() || null;
      skimlinkData.linkStore = store?.toLowerCase?.() || null;
      skimlinkData.linkSubRegion = linkSubregion.toLowerCase() || null;
    }

    // Passes link data to Omniture for tracking if present.
    const isAnalyticsVerified = await verifyOm(100, 10);
    if (isAnalyticsVerified) {
      om.trackLink(skimlinkData);
    }
  };

  useEffect(() => {
    const skimlinkLinks = document.querySelectorAll('a[data-link-type=\'skimlinks\'][data-promotional=\'true\']');

    // Attaches click event listener to ping Adobe Analytics with link data.
    for (let i = 0; i < skimlinkLinks.length; i += 1) {
      skimlinkLinks[i].addEventListener('click', fireTracking, false);
    }

    return async () => {
      for (let i = 0; i < skimlinkLinks.length; i += 1) {
        skimlinkLinks[i].removeEventListener('click', fireTracking, false);
      }
    };
  });

  return (
    <>
      <Head>
        <script key='skimlinks' type='text/plain' className='optanon-category-4' src={ data.skimlinksQuery.skimlinks_script_url } />
      </Head>
      <div className='content__disclaimer'>
        { disclaimer }
      </div>
    </>
  );
}

export default Skimlinks;
