import { useState } from 'react';
import LinkTracker from '~/components/linkTracker/linkTracker';

const ImageHero = ({ blockItem }) => {
  const {
    field_desktop_image = {},
    field_mobile_image = {},
    field_override_class
  } = blockItem;

  const [socialLinks] = useState([
    { 
      name: 'youtube',
      link: 'https://www.youtube.com/TheDrewBarrymoreShow',
    },
    {
      name: 'tiktok',
      link: 'https://www.tiktok.com/@thedrewbarrymoreshow',
    },
    {
      name: 'instagram',
      link: 'https://www.instagram.com/TheDrewBarrymoreShow/',
    },
    {
      name: 'facebook',
      link: 'https://www.facebook.com/TheDrewBarrymoreShow',
    },
    {
      name: 'giphy',
      link: 'https://giphy.com/thedrewbarrymoreshow/',
    }

  ]);

  return (
    <div className={`home-hero${field_override_class ? ` ${field_override_class}` : ''}`}>
      <div className="image-hero">
        <img
          alt={field_mobile_image.alt || 'the drew barrymore show'}
          className="image-hero__bg-image mobile-only"
          src={field_mobile_image.field_image?.image?.url || '/img/hero/homepage_mobile.jpg'}
        />
        <img
          alt={field_desktop_image.alt || 'the drew barrymore show'}
          className="image-hero__bg-image desktop-only"
          src={field_desktop_image.field_image?.image?.url || '/img/hero/homepage_desktop.jpg'}
        />
        <div className="image-hero__links">
          <LinkTracker
            destinationUrl='/watch-on-tv'
            linkCta="where to watch"
            linkFormat="button"
            linkRegion="home page"
            linkSubRegion="hero"
          >
            <a
              className="image-hero__link"
              data-autotrack="false"
              href="/watch-on-tv"
            >
              <img
                alt="where to watch"
                className="image-hero__link-text"
                src="/img/hero/where-to-watch.svg"
              />
            </a>
          </LinkTracker>
          <LinkTracker
            destinationUrl="https://1iota.com/show/1217/the-drew-barrymore-show"
            linkCta="tickets"
            linkFormat="button"
            linkRegion="home page"
            linkSubRegion="hero"
          >
            <a
              className="image-hero__link"
              data-autotrack="false"
              href="https://1iota.com/show/1217/the-drew-barrymore-show"
            >
              <img
                alt="tickets"
                className="image-hero__link-text"
                src="/img/hero/tickets.svg"
              />
            </a>
          </LinkTracker>
          <LinkTracker
            destinationUrl="/share-your-story"
            linkCta="be on the show"
            linkFormat="button"
            linkRegion="home page"
            linkSubRegion="hero"
          >
            <a
              className="image-hero__link"
              data-autotrack="false"
              href="share-your-story"
            >
              <img
                alt="be on the show"
                className="image-hero__link-text"
                src="/img/hero/be-on-the-show.svg"
              />
            </a>
          </LinkTracker>
        </div>
      </div>
      <div className="follow-drew">
        <h2 className="follow-drew__text">follow drew</h2>
        <div className="follow-drew__social-links">
          {socialLinks.map((platform) => (
            <LinkTracker
              key={platform.name}
              destinationUrl={platform.link}
              linkCta={`${platform.name} logo`}
              linkFormat="image"
              linkRegion="home page"
              linkSubRegion="follow drew"
            >
              <a
                className="follow-drew__social-link"
                data-autotrack="false"
                href={platform.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  alt={platform.name}
                  className="follow-drew__social-link-img"
                  src={`/img/icons/${platform.name}-circle-white.svg`}
                />
              </a>
            </LinkTracker>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImageHero;
