import Link from 'next/link';

const WhereToWatchBlock = () => {
  return (
    <div className='virtual-audience'>
      <h2>where to watch</h2>
      <p>find your station - watch the show</p>
      <Link href="/watch-on-tv" passHref>
        <a className="virtual-audience__btn">choose your location</a>
      </Link>
    </div>
  );
};

export default WhereToWatchBlock;
