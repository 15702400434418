import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import { getIcon } from '~/utils/utils';
import VideoPreview from '../videoPreview/videoPreview';
import Share from '~/components/share/share';

/**
 * Hero Video Block
 * @category Functional Component
 * @summary Displays the Hero Video Block.
 * @prop {object} blockItem - The blocks content.
 * @return {Node} React node containing the Hero Video Block.
 */

const BlockHeroVideo = props => {
  const { blockItem } = props;
  const content = blockItem?.field_scheduled_block?.resultRow
    ? blockItem?.field_scheduled_block?.resultRow[0].field_content_list_nodes[0]
    : blockItem?.resultRow[0];

  // Ensure object is not empty
  if(content && Object.keys(content).length === 0 && content.constructor === Object) {
    return false;
  }

  return (
    <div className={ `hero-video ${ blockItem.field_override_class }`}>
      <div className='constrain'>
        <div className='hero-video__grid'>
          <div className='hero-video__logo'>
            { blockItem?.field_image?.field_image?.logo?.url &&
              <img
                src={ blockItem.field_image.field_image.logo.url }
                alt={ blockItem.field_image.field_image.logo.alt }
              />
            }
          </div>
          <div>
            { content?.field_image?.field_image?.lg?.url &&
              <div className='hero-video__img'>
                <VideoPreview item={ content }>
                  <Link href={ content.path } passHref>
                    <a>
                      <div className='play-icon'>
                        <ReactSVG src='/img/icons/play.svg' />
                      </div>
                      <picture>
                        <source media='(max-width: 1068px)' srcSet={ content.field_image.field_image.sm.url } />
                        <img
                          src={ content.field_image.field_image.lg.url }
                          alt={ content.field_image?.alt }
                          width={ content.field_image.field_image.lg.width }
                          height={ content.field_image.field_image.lg.height }
                          className='img-full'
                          loading='lazy'
                        />
                      </picture>
                    </a>
                  </Link>
                </VideoPreview>
              </div>
            }
            { content.title &&
              <div className='hero-video__desc'>
                <div>
                  <ReactSVG src={ getIcon(content.__typename) } />
                </div>
                <h1>{ content.title }</h1>
                <div className='hero-video__desc-social'>
                  <Share />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockHeroVideo;
