import Plx from 'react-plx';

/**
 * Parallax Image
 * @category Functional Component
 * @extends Component
 * @summary Creates a parallax image.
 * @prop {Object} image - The image url.
 * @return {Node} React node containing the parallax image.
 */

const ParallaxImage = (props) => {
  const { children } = props;
  const parallaxData = [
    {
      start: 'self',
      duration: 200,
      properties: [
        {
          startValue: -200,
          endValue: 20,
          property: 'translateY',
        }
      ],
    }
  ];

  return (
    <div>
      <div className='parallax-image'>
        <Plx parallaxData={ parallaxData }>
          <div className='parallax-image__img'>
            { children }
          </div>
        </Plx>
      </div>
    </div>
  );
}

export default ParallaxImage;
