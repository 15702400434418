import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import CarouselContent from '~/components/carouselContent/carouselContent';
import VideoPreview from '~/components/videoPreview/videoPreview';
import { stripTags, getIcon } from '~/utils/utils';
import SiteSettings from '~/siteSettings';
import LinkTracker from '~/components/linkTracker/linkTracker';

/**
 * Carousel with Feature
 * @category Functional Component
 * @summary Displays a carousel with a featured item.
 * @prop {object} content - The blocks content.
 * @prop {string} overrideClass - Class to override this blocks style.
 * @prop {string} loop - Should the carousel loop? Accepted values 'yes' & 'no'.
 * @return {Node} React node containing a carousel with a featured item.
 */

const CarouselContentWithFeature = props => {
  const { content, overrideClass, loop } = props;
  const items = content?.field_content_list_nodes || content?.field_scheduled_block?.resultRow?.[0]?.field_content_list_nodes
  const _content = JSON.parse(JSON.stringify(items)); // We need a deep copy here
  const feature = _content.shift();

  return (
    <div className={`carousel-with-feature ${ overrideClass }`}>
      <div className='constrain'>
        <h2>{ content.field_display_title || content.info }</h2>
        <div className='carousel-with-feature__grid'>
          <div className='carousel-with-feature__img'>
            <div className='wrap-relative'>
              <VideoPreview item={ feature }>
                <LinkTracker
                  destinationUrl={ feature.path }
                  linkCta={ feature.title }
                  linkFormat='image'
                  linkRegion={ content.field_display_title || content.info }
                  linkSubRegion='feature image'
                >
                  <Link href={ feature.path } passHref>
                    <a data-autotrack='false'>
                      <div className='play-icon'>
                        <ReactSVG src='/img/icons/play.svg' />
                      </div>
                      <picture>
                        <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ feature.field_image.field_image.featured_sm.url } />
                        <img
                          src={ feature.field_image.field_image.featured_lg.url }
                          alt={ feature.field_image?.alt }
                          width={ feature.field_image.field_image.featured_lg.width }
                          height={ feature.field_image.field_image.featured_lg.height }
                          className='img-full'
                          loading='lazy'
                        />
                      </picture>
                    </a>
                  </Link>
                </LinkTracker>
              </VideoPreview>
            </div>
          </div>
          <div className='carousel-with-feature__desc'>
            <div>
              <div className='carousel-with-feature__icon'>
                <ReactSVG src={ getIcon(feature.__typename) } />
              </div>
              <h3>
                { feature.title }
              </h3>
              { feature.field_category && feature.field_category.path &&
                <div className='carousel-with-feature__category'>
                  <LinkTracker
                    destinationUrl={ feature.field_category.path }
                    linkCta={ feature.field_category.name }
                    linkFormat='image'
                    linkRegion={ content.field_display_title || content.info }
                    linkSubRegion='feature category'
                  >
                    <Link href={ feature.field_category.path } passHref>
                      <a data-autotrack='false'>
                        { feature.field_category.name }
                      </a>
                    </Link>
                  </LinkTracker>
                </div>
              }
              { feature.body_raw &&
                <div
                  className='carousel-with-feature__body'
                  dangerouslySetInnerHTML={{__html: stripTags(feature.body_raw)}}
                ></div>
              }
            </div>
          </div>
        </div>
      </div>
      <CarouselContent
        hideTypeIcons
        imgStyleLg='caro_sm'
        imgStyleSm='caro_sm'
        loop={ loop }
        items={ _content }
        title={ content.field_display_title || content.info }
      />
    </div>
  );
}

export default CarouselContentWithFeature;
