import { useState } from 'react';
import Link from 'next/link';
import key from 'weak-key';
import { get } from '~/utils/utils';
import SiteSettings from '~/siteSettings';

/**
 * Featured Comedians Block
 * @category Functional Component
 * @summary Displays a featured comedians block.
 * @prop {Object} blockItem - The block item's content.
 * @return {Node} React node containing the featured comedian block.
 */

const BlockFeaturedComedians = props => {
  const { content } = props;
  const comedians = content.field_content_list_nodes;
  const [currentGuest, setCurrentGuest] = useState(0);

  const selectEpisode = (e) => {
    setCurrentGuest(parseInt(e.currentTarget.dataset.episode, 10));
  }

  return (
    <div className='wrap-bg-polka'>
      <div className='constrain'>
        <div className='block-schedule block-featured-comedians'>
          <h2 className='block-schedule__heading'>
            { content.info }
          </h2>
          <div className='block-schedule__grid'>
            { comedians.map((comedian, i) => (
              <div key={`episode-selector-${key(comedian)}`}>
                <h3 className='block-schedule__item-mobile-label'>
                  { comedian.mobileLabel }
                </h3>
                <button
                  type='button'
                  className='block-schedule__item'
                  onClick={ selectEpisode }
                  key={`schedule-item-${key(comedian)}`}
                  data-episode={ i }
                >
                  { comedian?.field_image?.field_image?.lg?.url &&
                    <picture>
                      <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ comedian.field_image.field_image.seemore_xs.url } />
                      <img
                        src={ comedian.field_image.field_image.plug_sm.url }
                        alt={ comedian.field_image?.alt }
                        width={ comedian.field_image.field_image.plug_sm.width }
                        height={ comedian.field_image.field_image.plug_sm.height }
                        className={ `block-schedule__img${ i === currentGuest ? ' block-schedule__img--active' : '' }` }
                      />
                    </picture>
                  }
                  <div className={`block-schedule__overlay${ i === currentGuest ? ' block-schedule__overlay--active' : '' }`}>
                    <h3>{ comedian.label }</h3>
                  </div>
                </button>
              </div>
            ))}
          </div>
          { comedians[currentGuest] &&
            <div className='block-schedule__content'>
              { comedians[currentGuest]?.field_image?.field_image &&
                <div className='block-schedule__bio-image'>
                  <img
                    src={ comedians[currentGuest].field_image.field_image.seemore_lg.url }
                    alt={ comedians[currentGuest].field_image?.alt }
                    width={ comedians[currentGuest].field_image.field_image.seemore_lg.width }
                    height={ comedians[currentGuest].field_image.field_image.seemore_lg.height }
                    className='block-schedule__img'
                  />
                </div>
              }
              <div className='block-schedule__bio-info'>
                <h2>
                  { comedians[currentGuest].title }
                </h2>
                <ul className='block-schedule__contact'>
                { comedians[currentGuest].field_city && comedians[currentGuest].field_state &&
                  <li className='block-schedule__contact-address'>{ comedians[currentGuest].field_city }, { comedians[currentGuest].field_state }</li>
                }
                { comedians[currentGuest].field_website_url &&
                  <li className='block-schedule__contact-website'>
                    <a href={ comedians[currentGuest].field_website_url.uri } target='_blank' rel='noopener noreferrer'>{ comedians[currentGuest].field_website_url.title }</a>
                  </li>
                }
                { get(comedians[currentGuest], 'field_social_media_url.links') && comedians[currentGuest].field_social_media_url.links.map((social, i) =>
                  <li key={`social-${i.toString()}`} className='block-schedule__contact-social'>
                    <a href={ social.uri } target='_blank' rel='noopener noreferrer'>{ social.title }</a>
                  </li>
                )}
                </ul>
                { comedians[currentGuest].field_bio &&
                  <div dangerouslySetInnerHTML={{__html: comedians[currentGuest].field_bio}} className='block-schedule__copy'></div>
                }
                { get(comedians[currentGuest], 'field_video.path') &&
                  <Link href={ comedians[currentGuest].field_video.path }>
                    <a
                      title={ `Watch ${ comedians[currentGuest].title }'s Video` }
                      aria-label='watch video'
                      className='btn__cta'
                    ><span>watch video</span></a>
                  </Link>
                }
                <div className='spacer--2'></div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default BlockFeaturedComedians;