import { memo, Fragment } from 'react';
import Link from 'next/link';
import key from 'weak-key';
import { withRouter } from 'next/router';
import { ReactSVG } from 'react-svg';
import { getIcon, chunkBy } from '~/utils/utils';
import SiteSettings from '~/siteSettings';

/**
 * Plugger Block
 * @category Component
 * @extends Component
 * @summary Displays the plugger block.
 * @prop {Object} items - The block item's content.
 * @return {Node} React node containing the plugger block.
 */

const BlockPlugger = props => {
  const { blockItem, router } = props;
  const chunks = chunkBy(blockItem.field_content_list_nodes, [1,2,3]);
  const getImage = (i, item) => {
    let img = '';

    switch(i) {
      case 0:
        img = item.field_image.field_image.plug_lg;
        break;
      case 1:
        img = item.field_image.field_image.seemore_lg;
        break;
      default:
        img = item.field_image.field_image.plug_sm;
    }

    return img;
  };
  const isAltDisplay = router.pathname !== '/share-your-story';

  return (
    <div className={ isAltDisplay ? 'wrap-bg-primary plug__wrap' : '' }>
      <div className='constrain'>
        <div className='plug__header'>
          <div className='plug__header-icon'>
            <img
              src='/img/drew-sig-lg.png'
              width='60'
              height='63'
              alt='Share your Story'
              loading='lazy'
            />
          </div>
          <h1>{blockItem.info}</h1>
        </div>
        { blockItem.field_content_list_nodes.length &&
          <>
            {chunks.map((chunk, i) => (
              <Fragment key={`chunk-plug-${key(chunk)}`}>
                { !(isAltDisplay && i === 0) &&
                  <>
                    <div className={`plugger-grid-${ i + 1 < 3  ? i + 1 : 3 }`}>
                      {chunk.map((item) => (
                        <Fragment key={`card-plug-${key(item)}`}>
                          {item.__typename === 'plug' &&
                            <div>
                              <Link href={ `/share-your-story/plug/${ item.field_plug_id }` }>
                                <a
                                  aria-label={`Visit ${ item.title }`}
                                  className={ i === 0 || i === 1 ? 'card-interior' : 'card-interior card-interior--inline' }
                                >
                                  { item.field_image &&
                                    <picture>
                                      <source
                                        media={ `(max-width: ${ SiteSettings.breakpoints.sm })` }
                                        srcSet={ i === 0 || i === 1 ? item.field_image.field_image.seemore_sm.url : item.field_image.field_image.plug_sm.url }
                                      />
                                      <img
                                        src={ getImage(i, item).url }
                                        width={ getImage(i, item).width }
                                        height={ getImage(i, item).height }
                                        alt={ item.field_image.alt }
                                        className='card-interior__img'
                                        loading='lazy'
                                      />
                                    </picture>
                                  }
                                  <h2>
                                    { (i === 0 || i === 1) &&
                                      <div className='card-interior__type-icon'>
                                        <ReactSVG src={ getIcon(item.__typename) } />
                                      </div>
                                    }
                                    { item.title }
                                  </h2>
                                  { (i === 0 || i === 1) &&
                                    <div className='card-interior__mask'></div>
                                  }
                                </a>
                              </Link>
                            </div>
                          }
                        </Fragment>
                      ))}
                    </div>
                  </>
                }
              </Fragment>
            ))}
          </>
        }
        { isAltDisplay &&
          <div className='text-center'>
            <Link href='/share-your-story'>
              <a className='btn__cta'>
                <span>browse all topics</span>
              </a>
            </Link>
          </div>
        }
      </div>
    </div>
  );
}

export default withRouter(memo(BlockPlugger));
