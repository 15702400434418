import Link from 'next/link';
import { uniqBy } from 'lodash';

/**
 * Taxonomy Links
 * @category Functional Component
 * @summary Displays the category, secondary category and tags links.
 * @prop {object} content - The blocks content.
 * @return {Node} React node containing the tax links block.
 */

const TaxLinks = props => {
  const { content } = props;
  const categories = uniqBy([
    content?.field_category,
    ...(content.field_categories ?? [])
  ], 'tid');

  const getMarkup = (label, links) => {
    if(links && links.length > 0) {
      return(
        <div className='content__tags'>
          <h3>{ label }:</h3>
          <ul>
            { links.map((tag) => (
              <li key={`tag-${ tag?.tid }`}>
                <Link href={ tag?.path || '#' } passHref>
                  <a aria-label={`Visit ${ tag?.name }`}>
                    { tag?.name }
                  </a>
                </Link>
              </li>
            )) }
          </ul>
        </div>
      );
    }

    return false;
  }

  return (
    <>
      { getMarkup('tags', content.field_tags) }
      { getMarkup('categories', categories) }
    </>
  );
}

export default TaxLinks;
