import Link from 'next/link';

/**
 * Card Block
 * @category Functional Component
 * @summary Displays the card block.
 * @prop {object} content - The blocks content.
 * @return {Node} React node containing the card block.
 */

const BlockCard = props => {
  const { content } = props;

  return (
    <div className={`block-card ${ content.overrideClass || '' }`}>
      <div className='constrain block-card__grid'>
        <div className='block-card__logo'>
          { content.field_image &&
            <img
              className='img-full'
              src={ content.field_image.logo.url }
              alt={ content.field_image.alt }
              width={ content.field_image.logo.width }
              height={ content.field_image.logo.height }
            />
          }
        </div>
        <div className='block-card__desc'>
          <h2>{ content.info }</h2>
          <h3>{ content.subhead }</h3>
          <div className='block-card__cta'>
            <div
              className='block-card__body'
              dangerouslySetInnerHTML={{ __html: content.body_raw }}
            ></div>
            <br />
            { content.field_button_link && content.field_button_text &&
              <Link href={ content.field_button_link } passHref>
                <a
                  title={ content.field_button_text }
                  aria-label={ content.field_button_text }
                  className='btn__cta'
                >
                  <span>{ content.field_button_text }</span>
                </a>
              </Link>
            }
            { content.field_button_link && content.field_button_disclaimer &&
              <div
                className='block-card__disclaimer'
                dangerouslySetInnerHTML={{ __html: content.field_button_disclaimer }}
              ></div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockCard;
