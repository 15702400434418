import { useState, useEffect, useRef } from 'react';
import SiteSettings from '~/siteSettings';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(true);
  const tick = useRef(false);

  useEffect(() => {
    // On Init
    setIsMobile(window.innerWidth < parseInt(SiteSettings.breakpoints.sm, 10));

    const onResize = () => {
      const width = window.innerWidth;

      if (!tick.current) {
        window.requestAnimationFrame(() => {
          setIsMobile(width < parseInt(SiteSettings.breakpoints.sm, 10));
          tick.current = false;
        });

        tick.current = true;
      }
    }

    window.addEventListener('resize', onResize, true);
    return async () => {
      window.removeEventListener('resize', onResize, true);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;