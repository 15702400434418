import { useEffect, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import Store from '~/store/undux';
import { getDate, constructPlaylist } from '~/utils/utils';
import Category from '~/components/category/category';
import BodyParser from '~/components/bodyParser/bodyParser';
import Share from '~/components/share/share';
import Skimlinks from '~/components/skimlinks/skimlinks';
import CarouselRecomendations from '~/components/carouselRecommendations/carouselRecommendations';
import Stick from '~/components/stick/stick';
import TaxLinks from '~/components/taxLinks/taxLinks';
import SiteSettings from '~/siteSettings';
import { useRouter } from 'next/router';
import { getVideoKeywords, mobileCheck } from '~/utils/utils';
import uuidv1 from 'uuid/v1';

const CmvAvia = dynamic(() => import('@cbsi-ctd/cmv_avia').then((mod) => mod.Player), { ssr: false });

/**
 * Video Block
 * @category Functional Component
 * @summary Displays a video block.
 * @prop {object} data - The page data.
 * @prop {string} fileUrl - The base domain.
 * @prop {Object} drupalSettings - The drupal settings.
 * @prop {Object} recomendations - Video recomendations if available
 * @return {Node} React node containing the video block.
 */

const VideoBlock = props => {
  const { data, fileUrl, drupalSettings, recommendations, store, parentNid, pageType, section } = props;
  const content = data?.nodeQuery || data;
  const playlist = useMemo(() => constructPlaylist(content, recommendations), [content, recommendations]);
  const setPlaylistIndex = store.set('playlistIndex');
  const playlistIndex = store.get('playlistIndex');
  const playlistIndexRef = useRef(playlistIndex);

  const router = useRouter();

  const metadata = {
    assetId: content.nid,
    assetPubDate: content?.field_display_date || null,
    assetTitle: content.title,
    assetType: 'content_video',
    authorName: 'The Drew Barrymore Show Staff',
    brandPlatformId: mobileCheck() ? 'drewbarrymore_site_mweb' : 'drewbarrymore_site_desktop',
    category: content?.field_category?.name || 'qos',
    contentBrand: SiteSettings.siteName,
    deviceType: mobileCheck() ? 'mobile' : 'desktop',
    duration: content?.field_video_mpx_id?.duration || content?.field_video?.field_video_mpx_id?.duration,
    gaAccount: 'UA-1072526-1',
    mediaContentType: 'vod',
    mediaAutoplayCustom: true,
    mediaId: content?.field_video_mpx_id?.mpx_id || content?.field_video_mpx_id?.reference_id,
    mii: {
      viewtime: '10',
      refreshcap: '1000',
    },
    pageName: `${SiteSettings.brand}:${router.asPath}`,
    pageURL: router.asPath,
    pageType: pageType,
    pageViewGuid: uuidv1(),
    parentNid: parentNid,
    section: section,
    siteCode: SiteSettings.brand,
    siteHier: `${section}|${pageType}`,
    sitePrimaryRsid: 'cbsidrewbarrymoresite',
    siteSection: section,
    siteType: 'responsive_web',
    thumbnail: `${ content?.field_video_mpx_id?.thumbnails?.[0].streamingUrl || content?.field_video?.field_video_mpx_id?.thumbnails?.[0].streamingUrl }?width=720&crop=16:9`,
    userState: 'not authenticated',
    userType: 'anon',
    videoAutoPlay: 'on',
    videoExists: 'true',
    videoId: content?.field_video_reference_id || content.reference_id || content?.field_video?.field_video_mpx_id?.reference_id,
    videoReferenceId: content?.field_video_reference_id || content.reference_id || content?.field_video?.field_video_mpx_id?.reference_id,
    videoTitle: content?.title,
    vidPlayerPos: 'hero',
    vidPlaylistPos: playlistIndex || '0',
  }

  useEffect(() => {
    playlistIndexRef.current = playlistIndex;
  }, [playlistIndex])


  let continuousPlay = 0;
  const onContentComplete = () => {
    continuousPlay = 1;
    const incrementedIndex = playlistIndexRef.current + 1
    setPlaylistIndex(incrementedIndex >= playlist.length ? 0 : incrementedIndex);
  }

  const onPlaylistComplete = () => {
    setPlaylistIndex(0);
  }

  return (
    <div className='content'>
      {/* Video Player */}
      <div className='content__header-video'>
        <div className='content__header-video-wrap'>
          { (content?.field_video_mpx_id?.hls_streaming_url || content?.field_video?.field_video_mpx_id?.hls_streaming_url)  &&
            <Stick>
              <CmvAvia
                autoplay='attemptUnmutedThenMuted'
                captions={content?.field_video_captions?.path}
                compactViewBreakpoint={480}
                continuousPlay={continuousPlay}
                enableOm
                fms={drupalSettings?.videoSettingsQuery?.enable_fms}
                hideAds={content.field_is_promotional}
                keywords={getVideoKeywords(content)}
                metadata={metadata}
                onContentComplete={onContentComplete}
                playlistArr={playlist}
                playlistIndex={playlistIndex}
                playlistCompleteAction={onPlaylistComplete}
                posterImageUrl={`${ content?.field_video?.field_video_mpx_id?.thumbnails?.[0].streamingUrl }?width=720&crop=16:9`}
                siteSettings={SiteSettings}
                useUnmuteCta={true}
              />
            </Stick>
          }
        </div>
      </div>
      { playlist?.length > 1 &&
        <CarouselRecomendations playlist={ playlist } />
      }
      <div className='constrain'>
        <div className='content__body'>
          <div className='content__share desktop-only'>
            <Share title={ content.title } />
          </div>
          {/* Title */}
          <h1 className='content__title-sm'>
            { content.title }
          </h1>
          {/* Is Promotional Flag */}
          { content.field_is_promotional && content.field_flag &&
            <div className='content__flag'>
              { content.field_flag }
            </div>
          }
          <Category content={content} />
          {/* Byline */}
          { content.field_display_date &&
            <div className='content__byline'>
              <div className='content__byline-date'>
                { getDate(content.field_display_date) }
              </div>
            </div>
          }
          {/* Skimlinks */}
          { content.field_skimlinks &&
            <Skimlinks
              type={ content.__typename }
              data={ drupalSettings }
            />
          }
          {/* Subhead */}
          { content.field_subhead &&
            <h2>
              { content.field_subhead }
            </h2>
          }
          {/* Body */}
          { content.body &&
            <div className='content__body-main content__body-main--larger'>
              <BodyParser
                baseDomain={ fileUrl }
                content={ content.body }
                drupalSettings={ drupalSettings }
              />
            </div>
          }
          <div className='content__spacer'></div>
          <TaxLinks content={ content } />
          <div data-ad-ref='leader-plus-flex-top' className='bidbarrel-ad'></div>
          <div data-ad-ref='mobile-plus-flex-top' className='bidbarrel-ad'></div>
        </div>
      </div>
    </div>
  );
}

export default Store.withStore(VideoBlock);
