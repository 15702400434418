import { useState } from 'react';
import Link from 'next/link';
import key from 'weak-key';
import VideoPreview from '../videoPreview/videoPreview';
import { min2secs } from '~/utils/utils';
import SiteSettings from '~/siteSettings';

/**
 * Videos Block
 * @category Functional Component
 * @summary Displays a static block.
 * @prop {Object} items - The block items.
 * @prop {Object} title - The block title.
 * @prop {bool} seeMore - Should we paginate results?.
 * @prop {bool} seeMoreAt - When should we paginate?.
 * @return {Node} React node containing the videos block.
 */

const BlockVideos = props => {
  const [isVisible, setIsVisible] = useState(0);
  const { title, seeMore, seeMoreAt } = props;
  let { items } = props;
  items = items?.filter?.((item) => item !== null);

  if(items?.length) {
    return (
      <div className='constrain'>
        <div className='block-videos'>
          { title &&
            <h2 className='block-videos__heading'>{ title }</h2>
          }
          <div className='block-videos__grid'>
            {items.map((item, i) => (
              <Link href={ item.path } key={`block-video-item-${key(item)}`}>
                <a
                  aria-label={`Visit ${ item.title }`}
                  className={ i >= seeMoreAt && !isVisible && seeMore ? 'block-videos__item visuallyhidden' : 'block-videos__item'}
                >
                  <VideoPreview item={ item }>
                    <div className='placed'>
                      <picture>
                        <source media={ `(max-width: ${ SiteSettings.breakpoints.sm })` } srcSet={ item.field_image.field_image.sm.url } />
                        <img
                          src={ item.field_image.field_image.lg.url }
                          alt={ item.field_image?.alt }
                          width={ item.field_image.field_image.lg.width }
                          height={ item.field_image.field_image.lg.height }
                          className='img-full placed-elem'
                          loading='lazy'
                        />
                      </picture>
                      <div className='placed-275x154'></div>
                    </div>
                    <h2>{ item.title }</h2>
                    <div className='block-videos__mask'></div>
                    { item.field_video_mpx_id && item.field_video_mpx_id.duration !== undefined &&
                      <div className='block-videos__duration'>
                        { min2secs(item.field_video_mpx_id.duration) }
                      </div>
                    }
                  </VideoPreview>
                </a>
              </Link>
            ))}
          </div>
          { !isVisible && seeMore && items.length > seeMoreAt &&
            <button
              aria-label='See More'
              type='button'
              onClick={() => setIsVisible(true)}
              className='btn__cta'
            ><span>see more</span></button>
          }
        </div>
      </div>
    );
  }

  return false;
}

export default BlockVideos;
