import { useState, useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

const useCaro = opts => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isInteracted, setIsInteracted] = useState(false);
  const [viewportRef, embla] = useEmblaCarousel(opts);
  const [forceRepaint, setForceRepaint] = useState(0);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    // Save Slide Index
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  const onSettle = useCallback((e) => {
    if (!embla) return;

    const slide = embla.slideNodes()[embla.selectedScrollSnap()];
    slide.focus();
  }, [embla]);

  const reloadEmbla = useCallback(() => {
    if (!embla) return;
    const oldEngine = embla.dangerouslyGetEngine();
    embla.reInit();
    const newEngine = embla.dangerouslyGetEngine();
    const propsToCopy = ['scrollBody', 'location', 'target'];
    propsToCopy.forEach((p) => Object.assign(newEngine[p], oldEngine[p]));
    const { index } = newEngine.scrollTarget.byDistance(0, false);
    newEngine.index.set(index);
    newEngine.animation.start();
  }, [embla]);

  useEffect(() => {
    if (!embla) return;

    // Set First Index
    onSelect();

    // Attach Events
    embla.on('select', onSelect);
    embla.on('settle', onSettle);
  }, [embla, onSelect, scrollNext, onSettle]);

  return { embla, viewportRef, selectedIndex, setSelectedIndex, scrollNext, scrollPrev, scrollTo, forceRepaint, isInteracted, setIsInteracted, onSelect, onSettle, reloadEmbla };
};

export default useCaro;
